import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "REST API",
  "slug": "restapi"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`REST API`}</h1>
    <p>{`Accelerate your growth with enableHR`}</p>
    <p>{`An integration with enableHR opens up opportunities to connect with more
than one million users around Australia and New Zealand who use enableHR's online
HR software`}</p>
    <p>{`You can use the enablehr REST API to create calls to get the data you
need to integrate with enablehr.`}</p>
    <p>{`Please click the links on the Left Hand Side to see details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      